import { Link } from "gatsby";
import React from "react";
import Seo from "~/components/Seo";
import samaImage from "~/images/sama.jpg";

function SamaPage() {
  return (
    <div className="Content-outer" id="yui_3_17_2_1_1671159857644_124">
      <main className="Main Main--page" id="yui_3_17_2_1_1671159857644_7826">
        <section
          className="Main-content"
          data-content-field="main-content"
          id="yui_3_17_2_1_1671159857644_7825"
        >
          <div
            className="sqs-layout sqs-grid-12 columns-12"
            data-type="page"
            id="page-5f3ab72437478573c453d2e0"
          >
            <div className="row sqs-row" id="yui_3_17_2_1_1671159857644_7824">
              <div
                className="col sqs-col-12"
                id="yui_3_17_2_1_1671159857644_7823"
              >
                <div
                  className="sqs-block image-block sqs-block-image sqs-text-ready"
                  data-block-type="5"
                  id="block-yui_3_17_2_1_1604016710390_7381"
                >
                  <div
                    className="sqs-block-content"
                    id="yui_3_17_2_1_1671159857644_7822"
                  >
                    <div
                      className="
          image-block-outer-wrapper
          layout-caption-below
          design-layout-inline
          combination-animation-none
          individual-animation-none
          individual-text-animation-none
        "
                      data-test="image-block-inline-outer-wrapper"
                      id="yui_3_17_2_1_1671159857644_7821"
                    >
                      <figure
                        className="
              sqs-block-image-figure
              intrinsic
            "
                        style={{ maxWidth: 2500 }}
                        id="yui_3_17_2_1_1671159857644_7820"
                      >
                        <div
                          className="image-block-wrapper"
                          data-animation-role="image"
                          id="yui_3_17_2_1_1671159857644_7819"
                        >
                          <div
                            className="sqs-image-shape-container-element has-aspect-ratio"
                            style={{
                              position: "relative",
                              paddingBottom: "68.31999969482422%",
                              overflow: "hidden",
                            }}
                            id="yui_3_17_2_1_1671159857644_7818"
                          >
                            <noscript>
                              <img src={samaImage} alt="sama_mockup_3.jpg" />
                            </noscript>
                            <img
                              className="thumb-image loaded"
                              data-src={samaImage}
                              data-image={samaImage}
                              data-image-dimensions="2500x1708"
                              data-image-focal-point="0.5,0.5"
                              data-load="false"
                              data-image-id="5f9b9124fa74f3314c9814c3"
                              data-type="image"
                              style={{
                                left: "-0.0319353%",
                                top: "0%",
                                width: "100.064%",
                                height: "100%",
                                position: "absolute",
                              }}
                              alt="sama_mockup_3.jpg"
                              data-image-resolution="1500w"
                              src={samaImage}
                            />
                          </div>
                        </div>
                      </figure>
                    </div>
                  </div>
                </div>
                <div
                  className="sqs-block html-block sqs-block-html"
                  data-block-type="2"
                  id="block-yui_3_17_2_1_1597683500287_9558"
                >
                  <div className="sqs-block-content">
                    <p style={{ whiteSpace: "pre-wrap" }}>
                      There has been a lot of{" "}
                      <Link to="/research/">research</Link> in math education
                      that shows that one of the biggest problems for students
                      is internalizing the idea of the equal symbol as a{" "}
                      <strong>
                        <em>relational</em>
                      </strong>{" "}
                      sign instead of an{" "}
                      <strong>
                        <em>operational</em>
                      </strong>{" "}
                      sign.
                    </p>
                    <h3 style={{ whiteSpace: "pre-wrap" }}>
                      Sama is a card game that teaches players the meaning of
                      equality in math.
                    </h3>
                    <p style={{ whiteSpace: "pre-wrap" }}>
                      We have all seen the equal operator ‘<strong>=</strong>’
                      used in math. Most of the time, we see it in problems that
                      ask us to <em>evaluate</em> something, like 3 + 4{" "}
                      <strong>=</strong> ? or 23 - 10 <strong>=</strong> ?. This
                      might make you think that when you see an ‘=’ you have to
                      evaluate something.
                    </p>
                    <p style={{ whiteSpace: "pre-wrap" }}>
                      That is not always the case!
                    </p>
                    <h3 style={{ whiteSpace: "pre-wrap" }}>
                      <strong>
                        ‘=’ is a relationship operator that says that what is on
                        the left of the sign is equal to what is on the right of
                        the sign.
                      </strong>
                    </h3>
                    <p style={{ whiteSpace: "pre-wrap" }}>
                      3 + 4 <strong>=</strong> 7 is correct. So is 3 + 4{" "}
                      <strong>=</strong> 14 - 7.
                    </p>
                    <p style={{ whiteSpace: "pre-wrap" }}>
                      22 - 9 <strong>=</strong> 13 is correct. So is 22 - 9{" "}
                      <strong>=</strong> 10 + 3.
                    </p>
                    <p style={{ whiteSpace: "pre-wrap" }}>
                      Can you make equal expressions with the cards in your
                      hand? Who will reach the goal first?
                    </p>
                    <p
                      data-rte-preserve-empty="true"
                      style={{ whiteSpace: "pre-wrap" }}
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export function Head() {
  return <Seo title="Sama" />;
}

export default SamaPage;
